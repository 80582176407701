import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { PasswordResetSuccessComponent } from '../pages/password-reset/password-reset-success/password-reset-success.component';
import { PasswordResetComponent } from '../pages/password-reset/password-reset.component';
import { TermsComponent } from '../pages/terms/terms.component';
import { PathRoutes } from './path-routes';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: PathRoutes.Home.replace('/', ''), component: HomeComponent },

  { path: PathRoutes.Password.Reset.replace('/', '') + '/:token', component: PasswordResetComponent },
  { path: PathRoutes.Password.ResetSuccess.replace('/', ''), component: PasswordResetSuccessComponent },

  { path: PathRoutes.Terms.replace('/', ''), component: TermsComponent },

  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
