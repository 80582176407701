import { Endpoints } from './../routes/endpoints';
import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private network: NetworkService) { }

  getTerms() {
    return this.network.get(`${Endpoints.Settings.Terms}`)
  }

}
