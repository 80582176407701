import Swal from 'sweetalert2';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  notificationBehavior = new BehaviorSubject<Notification>(null)
  notificationWatcher = this.notificationBehavior.asObservable()

  public masks = {
    mobile: "(00) 00000-0000",
    landline: "(00) 0000-0000",
    cep: "00000-000",
    cpf: "000.000.000-00",
    number: (qtd) => {
      var n = ''
      for (var i = 0; i < qtd; i++) {
        n += '0'
      }
      return n
    }
  }
  public ufs = [
    { "nome": "Acre", "sigla": "AC" },
    { "nome": "Alagoas", "sigla": "AL" },
    { "nome": "Amapá", "sigla": "AP" },
    { "nome": "Amazonas", "sigla": "AM" },
    { "nome": "Bahia", "sigla": "BA" },
    { "nome": "Ceará", "sigla": "CE" },
    { "nome": "Distrito Federal", "sigla": "DF" },
    { "nome": "Espírito Santo", "sigla": "ES" },
    { "nome": "Goiás", "sigla": "GO" },
    { "nome": "Maranhão", "sigla": "MA" },
    { "nome": "Mato Grosso", "sigla": "MT" },
    { "nome": "Mato Grosso do Sul", "sigla": "MS" },
    { "nome": "Minas Gerais", "sigla": "MG" },
    { "nome": "Pará", "sigla": "PA" },
    { "nome": "Paraíba", "sigla": "PB" },
    { "nome": "Paraná", "sigla": "PR" },
    { "nome": "Pernambuco", "sigla": "PE" },
    { "nome": "Piauí", "sigla": "PI" },
    { "nome": "Rio de Janeiro", "sigla": "RJ" },
    { "nome": "Rio Grande do Norte", "sigla": "RN" },
    { "nome": "Rio Grande do Sul", "sigla": "RS" },
    { "nome": "Rondônia", "sigla": "RO" },
    { "nome": "Roraima", "sigla": "RR" },
    { "nome": "Santa Catarina", "sigla": "SC" },
    { "nome": "São Paulo", "sigla": "SP" },
    { "nome": "Sergipe", "sigla": "SE" },
    { "nome": "Tocantins", "sigla": "TO" }

  ]

  public banksArray = [
    {
      "code": "356",
      "name": "ABN Amro Real"
    },
    {
      "code": "204",
      "name": "American Express Bank"
    },
    {
      "code": "039",
      "name": "BEP"
    },
    {
      "code": "752",
      "name": "BNP Paribas"
    },
    {
      "code": "069",
      "name": "BPN Brasil"
    },
    {
      "code": "741",
      "name": "BRP"
    },
    {
      "code": "044",
      "name": "BVA"
    },
    {
      "code": "654",
      "name": "Banco A.J. Renner"
    },
    {
      "code": "246",
      "name": "Banco ABC Brasil"
    },
    {
      "code": "025",
      "name": "Banco Alfa"
    },
    {
      "code": "213",
      "name": "Banco Arbi"
    },
    {
      "code": "107",
      "name": "Banco BBM"
    },
    {
      "code": "096",
      "name": "Banco BMF"
    },
    {
      "code": "318",
      "name": "Banco BMG"
    },
    {
      "code": "740",
      "name": "Banco Barclays"
    },
    {
      "code": "031",
      "name": "Banco Beg"
    },
    {
      "code": "036",
      "name": "Banco Bem"
    },
    {
      "code": "248",
      "name": "Banco Boavista Interatlântico"
    },
    {
      "code": "218",
      "name": "Banco Bonsucesso"
    },
    {
      "code": "225",
      "name": "Banco Brascan"
    },
    {
      "code": "075",
      "name": "Banco CR2"
    },
    {
      "code": "263",
      "name": "Banco Cacique"
    },
    {
      "code": "222",
      "name": "Banco Calyon Brasil"
    },
    {
      "code": "412",
      "name": "Banco Capital"
    },
    {
      "code": "040",
      "name": "Banco Cargill"
    },
    {
      "code": "745",
      "name": "Banco Citibank"
    },
    {
      "code": "241",
      "name": "Banco Clássico"
    },
    {
      "code": "753",
      "name": "Banco Comercial Uruguai"
    },
    {
      "code": "748",
      "name": "Banco Cooperativo Sicredi"
    },
    {
      "code": "756",
      "name": "Banco Cooperativo do Brasil"
    },
    {
      "code": "721",
      "name": "Banco Credibel"
    },
    {
      "code": "505",
      "name": "Banco Credit Suisse"
    },
    {
      "code": "229",
      "name": "Banco Cruzeiro"
    },
    {
      "code": "266",
      "name": "Banco Cédula"
    },
    {
      "code": "707",
      "name": "Banco Daycoval"
    },
    {
      "code": "214",
      "name": "Banco Dibens"
    },
    {
      "code": "265",
      "name": "Banco Fator"
    },
    {
      "code": "224",
      "name": "Banco Fibra"
    },
    {
      "code": "626",
      "name": "Banco Ficsa"
    },
    {
      "code": "175",
      "name": "Banco Finasa"
    },
    {
      "code": "233",
      "name": "Banco GE Capital"
    },
    {
      "code": "739",
      "name": "Banco Galvão de Negócios"
    },
    {
      "code": "734",
      "name": "Banco Gerdau"
    },
    {
      "code": "612",
      "name": "Banco Guanabara"
    },
    {
      "code": "604",
      "name": "Banco Industrial"
    },
    {
      "code": "320",
      "name": "Banco Industrial e Comercial"
    },
    {
      "code": "653",
      "name": "Banco Indusval"
    },
    {
      "code": "077",
      "name": "Banco Inter"
    },
    {
      "code": "630",
      "name": "Banco Intercap"
    },
    {
      "code": "184",
      "name": "Banco Itaú BBA"
    },
    {
      "code": "074",
      "name": "Banco J. Safra"
    },
    {
      "code": "217",
      "name": "Banco Joh Deere"
    },
    {
      "code": "076",
      "name": "Banco KDB"
    },
    {
      "code": "495",
      "name": "Banco La Provincia de Buenos Aires"
    },
    {
      "code": "600",
      "name": "Banco Luso Brasileiro"
    },
    {
      "code": "212",
      "name": "Banco Matone"
    },
    {
      "code": "394",
      "name": "Banco Mercantil de Crédito"
    },
    {
      "code": "389",
      "name": "Banco Mercantil do Brasil"
    },
    {
      "code": "755",
      "name": "Banco Merrill Lynch"
    },
    {
      "code": "746",
      "name": "Banco Modal"
    },
    {
      "code": "738",
      "name": "Banco Morada"
    },
    {
      "code": "488",
      "name": "Banco Morgan Guaranty"
    },
    {
      "code": "066",
      "name": "Banco Morgan Stanley Dean Witter"
    },
    {
      "code": "492",
      "name": "Banco NMB Postbank"
    },
    {
      "code": "735",
      "name": "Banco Neon"
    },
    {
      "code": "045",
      "name": "Banco Opportunity"
    },
    {
      "code": "208",
      "name": "Banco Pactual"
    },
    {
      "code": "623",
      "name": "Banco Panamericano"
    },
    {
      "code": "611",
      "name": "Banco Paulista"
    },
    {
      "code": "613",
      "name": "Banco Pecunia"
    },
    {
      "code": "643",
      "name": "Banco Pine"
    },
    {
      "code": "073",
      "name": "Banco Popular"
    },
    {
      "code": "638",
      "name": "Banco Prosper"
    },
    {
      "code": "747",
      "name": "Banco Rabobank International"
    },
    {
      "code": "633",
      "name": "Banco Rendimento"
    },
    {
      "code": "072",
      "name": "Banco Rural"
    },
    {
      "code": "453",
      "name": "Banco Rural"
    },
    {
      "code": "422",
      "name": "Banco Safra"
    },
    {
      "code": "351",
      "name": "Banco Santander"
    },
    {
      "code": "033",
      "name": "Banco Santander Banespa"
    },
    {
      "code": "353",
      "name": "Banco Santander Brasil"
    },
    {
      "code": "250",
      "name": "Banco Schahin"
    },
    {
      "code": "743",
      "name": "Banco Semear"
    },
    {
      "code": "749",
      "name": "Banco Simples"
    },
    {
      "code": "366",
      "name": "Banco Societe Generale Brasil"
    },
    {
      "code": "637",
      "name": "Banco Sofisa"
    },
    {
      "code": "243",
      "name": "Banco Stock Máxima"
    },
    {
      "code": "464",
      "name": "Banco Sumitomo Mitsui Brasileiro"
    },
    {
      "code": "456",
      "name": "Banco Tokyo Mitsubishi UFJ"
    },
    {
      "code": "634",
      "name": "Banco Triângulo"
    },
    {
      "code": "610",
      "name": "Banco VR"
    },
    {
      "code": "655",
      "name": "Banco Votorantim"
    },
    {
      "code": "370",
      "name": "Banco WestLB"
    },
    {
      "code": "003",
      "name": "Banco da Amazônia"
    },
    {
      "code": "070",
      "name": "Banco de Brasília – BRB"
    },
    {
      "code": "300",
      "name": "Banco de la Nación Argentina"
    },
    {
      "code": "001",
      "name": "Banco do Brasil"
    },
    {
      "code": "004",
      "name": "Banco do Nordeste"
    },
    {
      "code": "494",
      "name": "Banco la República Oriental del Uruguay"
    },
    {
      "code": "116",
      "name": "Banco Único"
    },
    {
      "code": "029",
      "name": "Banerj"
    },
    {
      "code": "047",
      "name": "Banese"
    },
    {
      "code": "038",
      "name": "Banestado"
    },
    {
      "code": "021",
      "name": "Banestes"
    },
    {
      "code": "719",
      "name": "Banif"
    },
    {
      "code": "037",
      "name": "Banpará"
    },
    {
      "code": "041",
      "name": "Banrisul"
    },
    {
      "code": "027",
      "name": "Besc"
    },
    {
      "code": "237",
      "name": "Bradesco"
    },
    {
      "code": "104",
      "name": "Caixa Econômica Federal"
    },
    {
      "code": "477",
      "name": "Citibank"
    },
    {
      "code": "487",
      "name": "Deutsche Bank"
    },
    {
      "code": "751",
      "name": "Dresdner Bank"
    },
    {
      "code": "252",
      "name": "Fininvest"
    },
    {
      "code": "399",
      "name": "HSBC"
    },
    {
      "code": "062",
      "name": "Hipercard"
    },
    {
      "code": "063",
      "name": "Ibibank"
    },
    {
      "code": "249",
      "name": "Investcred Unibanco"
    },
    {
      "code": "341",
      "name": "Itau"
    },
    {
      "code": "479",
      "name": "Itaubank (antigo Bank Boston)"
    },
    {
      "code": "652",
      "name": "Itaú Holding Financeira"
    },
    {
      "code": "376",
      "name": "JP Morgan"
    },
    {
      "code": "757",
      "name": "KEB"
    },
    {
      "code": "065",
      "name": "Lemon Bank"
    },
    {
      "code": "237",
      "name": "Next"
    },
    {
      "code": "151",
      "name": "Nossa Caixa"
    },
    {
      "code": "260",
      "name": "Nubank"
    },
    {
      "code": "254",
      "name": "Paraná Banco"
    },
    {
      "code": "347",
      "name": "Sudameris"
    },
    {
      "code": "409",
      "name": "Unibanco"
    },
    {
      "code": "230",
      "name": "Unicard"
    }
  ]

  public months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  public weekDays = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo']

  constructor() { }

  triggerNotification(success, title = '', text = '', timer = 4000) {
    let notification: any = {}

    notification.title = title || (success ? 'Informações salvas' : 'Erro ao salvar')
    notification.text = text || (success ? 'As informações foram salvas com sucesso' : 'Ocorreu um erro ao salvar as informações')
    notification.icon = success ? 'success' : 'error'
    notification.showCloseButton = true
    notification.showConfirmButton = false
    notification.timer = timer

    Swal.fire(notification)
  }
  getFormattedPrice(price: number, cifrao = true) {
    var type = cifrao ? 'currency' : 'decimal'
    return new Intl.NumberFormat('pt-BR', { style: type, currency: 'BRL' }).format(price);
  }

  getStringDate(date) {
    var d = new Date(date)
    return `${this.weekDays[d.getDay() - 1]}, ${d.getDate()} de ${this.months[d.getMonth()]} de ${d.getFullYear()}`
  }

  flatpickrDefaultDateFormatter() {
    var date = new Date(),
      month = (date.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      monthF = (month.length == 1) ? '0' + month : month,
      yearF = date.getFullYear();
    var dateInitial = (new Date(yearF, parseInt(month), 1 )).getDate().toString();
    var dateI = (dateInitial.length == 1) ? '0' + dateInitial : dateInitial
    var dateFinal = (new Date(yearF, parseInt(month), 0 )).getDate().toString();
    var dateF = (dateFinal.length == 1) ? '0' + dateFinal : dateFinal
    return `${dateI}/${monthF}/${yearF} até ${dateF}/${monthF}/${yearF}`
  }

  groupBy(collection, property) {
    var i = 0, val, index,
      values = [], result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1)
        result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }

    return result.map(function (r, index) {
      return { ...r[0], qtd: r.length }
    })
  }

  customSwal(){
    return Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-outline-secondary btn-del mr-2'
      },
      buttonsStyling: false
    })
  }

  cancelSwal(active) {
    return this.customSwal().fire({
      title: 'Você tem certeza?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: active == 1 ? 'Sim, ativar!' : 'Sim, desativar',
      cancelButtonText: 'Cancelar'
    })
  }

  debugInvalidFields(form: FormGroup) {
    console.log(' ---------- invalid fields ---------- ');

    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    console.log(invalid)

    console.log(' ---------- END OF invalid fields ---------- ');
}

paymentMethod(s){
  switch(s){
    case 'app_credit':
    return 'Crédito APP'

    case 'credit_card':
    return 'Cartão de Crédito'

    case 'bank_deposit':
    return 'Depósito Bancário'

    case 'on_site':
    return 'No local'

    case 'on_delivery':
    return 'Na entrega'
  }
}
transactionType(s){ //Types 'order','transfer','refound','prize','add_credit','withdraw'
  switch(s){
    case 'order':
    return 'Pedido'
    case 'transfer':
    return 'Transferência'
    case 'refound':
    return 'Reembolso'
    case 'prize':
    return 'Prêmio'
    case 'add_credit':
    return 'Adicionar crédito'
    case 'withdraw':
    return 'Resgate'

  }
}


getDateStringFromDate(date: Date, format: string = 'pt-BR'): string {
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return format === 'pt-BR' ? [
    (dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    date.getFullYear()
  ].join('/') :

    [
      date.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd,

    ].join('-')
    ;
}


}
