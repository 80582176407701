import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  SESSION_KEY = 'chefedeplantao_user_session'
  constructor() { }

  saveSession(params){
    
    localStorage.setItem(this.SESSION_KEY, JSON.stringify(params))
  }
  getSession(){
    return localStorage.getItem(this.SESSION_KEY) ? JSON.parse(localStorage.getItem(this.SESSION_KEY)) : null
  }
  destroySession(){
    localStorage.clear()
  }

}

