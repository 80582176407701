import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  terms: any

  constructor(private settingsService: SettingsService) {
  }

  ngOnInit() {
    this.getItem()
  }

  async getItem() {
    const res = await this.settingsService.getTerms()
    this.terms = res.data.value
  }


}
