
import { NetworkService } from './network.service';
import { Injectable } from '@angular/core';
import { Endpoints } from '../routes/endpoints';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private network: NetworkService) { }

  sendToken(params) {
    return this.network.post(Endpoints.PasswordEmail, params)
  }

  changePassword(params) {
    return this.network.post(Endpoints.PasswordReset, params)
  }

  getCep(cep) {
    return this.network.makeExternalRequest(`https://viacep.com.br/ws/${cep.replace('-', '')}/json/`, null, 'get')
  }

}
